// We have texts in database that has both \n and <br /> and other html tags for formating
// so we need to bring them to a html form(for now only replacing \n to <br />)
export const parseHtmlText = (text: string | null) => {
  if (!text) return null;

  return text
    .replace(/<strong>[\n\r\s\t]*<\/strong>\\*/g, '')
    .replace(/<span style="text-decoration: underline;">[\n\r\s\t]*<\/span>\\*/g, '')
    .replace(/<span style="text-decoration: italic;">[\n\r\s\t]*<\/span>\\*/g, '')
    .replace(/<p>[\n\r\s\t]*<\/p>\\*/g, '<br />')
    .replace(/\n/g, '<br />');
};
