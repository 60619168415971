import { COLORS } from '@sortlist-frontend/design-system';

export const hilightUrls = (text: string | null) => {
  if (!text) return null;

  const urlRegex = /"(https?:\/\/[^\s\]\[]+)"/g;
  return text.replace(urlRegex, function (url) {
    return `${url} style="color:${COLORS.PRIMARY_500};" `;
  });
};
